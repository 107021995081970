<template>
    <div style="padding-top: 0px; background: #fff;">
        <a-spin :spinning="isLoading">
            <div v-if="!isLoading && list.length == 0" style="padding: 80px;">
                <a-empty></a-empty>
            </div>
            <div v-if="isLoading && list.length == 0">
                <div style="padding: 16px;">
                    <a-skeleton acitive avatar :paragraph="{ rows: 1 }"></a-skeleton>
                </div>
                <div style="padding: 16px;">
                    <a-skeleton acitive avatar :paragraph="{ rows: 1 }"></a-skeleton>
                </div>
                <div style="padding: 16px;">
                    <a-skeleton acitive avatar :paragraph="{ rows: 1 }"></a-skeleton>
                </div>
            </div>
            <div v-for="(message, index) in list" style="display: flex; padding: 16px;border-bottom: 1px solid #ebebeb;">
                <div>
                    <a :href="getUserHref(message.userId)" target="_blank">
                        <DefaultAvatar :name="message.userName" :url="message.avatar" :size="54"></DefaultAvatar>
                    </a>
                </div>
                <div style="margin-left: 12px; color: rgba(0, 0, 0, 0.85); width: 0px; flex: 1; font-size: 16px;">
                    <div>
                        <a :href="getUserHref(message.userId)" target="_blank">
                            {{ message.userName  }}
                        </a>
                        <span style="margin: 0px 6px; color: rgba(0, 0, 0, 0.45)">
                            关注了你
                        </span>
                    </div>
                    <div style="color: rgba(0, 0, 0, 0.45); margin-top: 16px;">
                        {{  message.followDateTime  }}
                    </div>
                   <!--  <span>
                        <a :href="getPostHref(1)" target="_blank"> 《 ASP.NET Core 依赖注入DI容器 》</a>
                    </span> -->
                </div>
                <div>
                  <!--   <a-button >
                        关注
                    </a-button> -->
                    <SubScribeButton :message.sync="message" ></SubScribeButton>
                </div>
              <!--   <div>
                    <a :href="getPostHref(1)" target="_blank">
                        <img style=" width: 180px;height: 108px;object-fit: cover;"
                            src="https://saims.zkshare.com/filepaper/400242125557829/400242887749701.png" />
                    </a>
                </div> -->
            </div>

        </a-spin>
        <div v-if="!isSinglePage" class="pagination-ctn default-pagination">
            <a-pagination @change="handlePageChange" v-model="current" :pageSize="pageSize" :total="total" show-quick-jumper
                hideOnSinglePage  />
        </div>
    </div>
</template>

<script>
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue-demi';
import DefaultAvatar from '@/components/defaultAvatar.vue';
import store from '@/store';
import { getRecentFollowList } from '@/api/paperManage.js'
import SubScribeButton from './components/subScribeButton.vue';

export default {
    props: {},
    setup(props, context) {
        const isLoading = ref(true);
        const list = ref([]);
        const { $route, $router } = context.root;
        const isSelf = (userId) => {
            return store.state.userInfo.userId == userId;
        };
        const getUserHref = (userId) => {
            let realId = isSelf(userId) ? 0 : userId;
            const userHref = $router.resolve({
                name: "bbsUser",
                params: {
                    id: realId
                }
            });
            return userHref.href;
        };
        onBeforeMount(() => {
           /*  list.value = [1, 2, 3];
            isLoading.value = false; */
            handlePageChange();
            context.emit('onActiveChange', $route.name)
        })



        const getPostHref = (postId) => {
            const postHref = $router.resolve({
                name: "bbsPost",
                params: {
                    id: postId
                }
            })
            return postHref.href;
        }

        const current = ref(1);

        const pageSize = ref(15);

        const total = ref(0);

        const handlePageChange = async () => {
            isLoading.value = true;
            const res = await getRecentFollowList({
                pageSize: pageSize.value,
                pageNo: current.value
            });
            if(res.success) {
                list.value = res.data.rows;
                total.value = res.data.totalRows
            }
            isLoading.value = false;``
        }

        const isSinglePage = computed(() => {
            return pageSize.value >= total.value;
        })
        onBeforeUnmount(() => {
            store.commit('bbs/resetUserCount');
        })
        return {
            isLoading,
            list,
            getUserHref,
            getPostHref,
            current,
            pageSize,
            total,
            handlePageChange,
            isSinglePage
        };
    },
    components: { DefaultAvatar, SubScribeButton }
}
</script>

<style lang="less" scoped>
a {
    color: inherit;
}

.sub-btn, .unsub-btn {
            width: 110px;
            span {
                text-align: center;
            }
            text-align: center;
        }
        .unsub-btn {
            background: #EEEEEE;
            border: .5px solid transparent;
            color: rgba(0, 0, 0, 0.65);
            transition: .3s all ease-in-out;
        }
        .hover-btn {
            border: .5px solid @srims-primary-color;
            color: @srims-primary-color;
        }

.default-pagination {
    background: #fff;
    padding: 25px ;
    
}
</style>