<template>
    <div>
        <a-button type="primary" class="sub-btn" v-if="!message.isFollow" @click="handleSubToggle"
            :loading="isFollowing" :disabled="isFollowing" 
           > + 关注 </a-button>
        <a-button v-else :class="['unsub-btn']" @click="handleSubToggle">
            已关注
        </a-button>
    </div>
</template>

<script>
import { followUser, unFollowUser } from '@/api/paperManage.js';
import { message } from 'ant-design-vue';
import { ref } from 'vue-demi';
export default {
    props: {
        message: {
            default: () => ({})
        }
    },
    setup(props, context) {
  
        // 关注/未关注切换
        const handleSubToggle = async () => {
            const hasFollow = props.message.isFollow;
            isFollowing.value = true;
            if (hasFollow) {
                await unfollow();
            }
            else {
                await follow();
            }

            /* setTimeout(() => {
                isSub.value = !isSub.value;
                isHover.value = false;
                isSubLoading.value = false;
            },2000) */
        }



        const isFollowing = ref(false);  // 是否正在关注中

        const follow = async () => {
            isFollowing.value = true;
            const res = await followUser({
                userid: props.message.userId
            });
            if (res.success) {
                message.success('成功关注')
                props.message.isFollow = true;
            }
            isFollowing.value = false;
        }
        const unfollow = async () => {
            isFollowing.value = true;
            const formData = new FormData();
            formData.append('userid', props.message.userId)
            const res = await unFollowUser(formData);
            if (res.success) {
                message.info('已取消关注')
                props.message.isFollow = false;
            }
            isFollowing.value = false;
        }
        return {
            isFollowing,
            handleSubToggle
        }
    }

}
</script>

<style lang="less" scoped>
.sub-btn,
.unsub-btn {
    width: 110px;

    span {
        text-align: center;
    }

    text-align: center;
}

.unsub-btn {
    background: #EEEEEE;
    border: .5px solid transparent;
    color: rgba(0, 0, 0, 0.65);
    transition: .3s all ease-in-out;
}

.hover-btn {
    border: .5px solid @srims-primary-color;
    color: @srims-primary-color;
}
</style>